














































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI51LCPL3_Q8',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        product1: null,
        product2: null,
        product3: null,
      },
      options: [
        {
          label: 'Product 1: ',
          input: 'product1',
        },
        {
          label: 'Product 2: ',
          input: 'product2',
        },
        {
          label: 'Product 3: ',
          input: 'product3',
        },
      ],
      dropdownOptions: [
        {
          text: 'Structure A',
          value: 'a',
        },
        {
          text: 'Structure B',
          value: 'b',
        },
        {
          text: 'Structure C',
          value: 'c',
        },
        {
          text: 'Structure D',
          value: 'd',
        },
        {
          text: 'Structure E',
          value: 'e',
        },
        {
          text: 'Structure F',
          value: 'f',
        },
        {
          text: 'Structure G',
          value: 'g',
        },
        {
          text: 'Structure H',
          value: 'h',
        },
        {
          text: 'Structure I',
          value: 'i',
        },
        {
          text: 'Structure J',
          value: 'j',
        },
        {
          text: 'Structure K',
          value: 'k',
        },
        {
          text: 'Structure L',
          value: 'l',
        },
        {
          text: 'Structure M',
          value: 'm',
        },
        {
          text: 'Structure N',
          value: 'n',
        },
        {
          text: 'Structure O',
          value: 'o',
        },
      ],
    };
  },
};
